import React from 'react'
import { Layout } from '@components/Layout';
import { SEO, mapToSEO } from '@components/SEO';
import { GenericBannerContainer } from '@components/GenericBannerContainer';
import { CustomContainer, H2, Paragraph1 } from '@styles/Global.styles';
import { SignupBannerWrapper, mapToKiwiVIPVisible } from '@components/SignupBanner';
import { mapToPageHeader, Banner } from '@components/Banner';
import { graphql } from 'gatsby';
import { CompetitionForm } from '@components/CompetitionForm';
import { FormFieldData } from '@elements/Forms/types';
import { KenticoChoice } from '@utils/KontentTypes';
import { predefinedFields } from '@elements/Forms/constants.forms';
import { Grid } from 'semantic-ui-react';


interface Props{
    data: any,
    location: string
}
interface States{

}

const loadSelectedFields = (data: Array<KenticoChoice>) =>{
  let fields:FormFieldData[] = [];
  data.forEach(item=>{
    if(item.codename === "first_name"){
      fields.push(predefinedFields.firstname);
    }else if(item.codename === "last_name"){
      fields.push(predefinedFields.lastname);
    }else if(item.codename === "email"){
      fields.push(predefinedFields.email);
    }else{
      // standard input usage. not required
      const standardInput: FormFieldData =  {
        id: item.codename,
        name: item.codename,
        tag: "input",
        initialValue: "",
        label: item.name,
        type: "text",
        placeholder: "",
        isRequired: false,
        requiredErrorMessage: "",
        width:{
            mobile: 16,
            tablet: 16,
            computer: 16
        }
      }
      fields.push(standardInput);
    }
  });
  return fields;
}

class CompetitionLandingPage extends React.Component<Props,States>{
    render(){
        const {elements,system} = this.props.data.kontentItemCompetitionLandingPage;
        const apiUrl = this.props.data.site.siteMetadata.apiUrl;
        const emailValidationToken = this.props.data.site.siteMetadata.emailValidationToken;
        const seo = mapToSEO(elements);
        const kiwiVip = mapToKiwiVIPVisible(elements);
        const metadata = mapToPageHeader(elements);
        const heading = elements.heading.value;
        const content = elements.description.value;
        const contentAlignment = elements.description_alignment.value !== undefined && elements.description_alignment.value.length > 0 ? elements.description_alignment.value[0].name : 'left';
        const termsConditions = elements.t_c.value;
        const thank_you_message = elements.thank_you_message.value;
        const competitionName = system.name;
        const salesforceCampaignName = elements.campaign_name.value;

        let fields = loadSelectedFields(elements.competition_form_fields.value);
        let t_c = predefinedFields.termsConditions;
        t_c.checkboxLabelHtml = termsConditions;
        fields.push(t_c);

        return (<Layout location={this.props.location}>
            <SEO {...seo} />
            <Banner {...metadata} isBiggerBanner={true} />
            <GenericBannerContainer
              padding={{
                mobile: {
                    top: 60,
                    bottom: 60
                },
                desktop: {
                    top: 80,
                    bottom: 80
                }
              }}>
              <CustomContainer width="75%">
                <H2 textAlign="center">{heading}</H2>
                {/* <Paragraph1 id="___gatsby" textAlign={contentAlignment} dangerouslySetInnerHTML={{__html: content}} /> */}
                <Grid>
                  <Grid.Column textAlign={contentAlignment} width={16} dangerouslySetInnerHTML={{__html: content}}></Grid.Column>
                </Grid>
                <CompetitionForm apiUrl={apiUrl} emailValidationToken={emailValidationToken} fields={fields} success={thank_you_message} campaignName={salesforceCampaignName} competitionName={competitionName}/>
              </CustomContainer>
            </GenericBannerContainer>
            {
                kiwiVip.visible &&
                <SignupBannerWrapper version= {kiwiVip.version} />
            }
      </Layout>);
    }
}

export const query = graphql`
query($slug: String!){
  site {
    siteMetadata {
      apiUrl
      emailValidationToken
    }
  }
  kontentItemCompetitionLandingPage(fields: { slug: { eq: $slug }}) {
      system{
        name
      }
      elements {
        heading {
          value
        }
        campaign_name {
          value
        }
        description{
          value
        }
        description_alignment {
          value {
            name
            codename
          }
        }
        thank_you_message{
          value
        }
        competition_form_fields{
            value{
                name
                codename
            }
        }
        t_c{
          value
        }
        seo__noindex {
          value {
            name
            codename
          }
        }
        seo__nofollow {
          value {
            name
            codename
          }
        }
        seo__page_title {
          value
        }
        seo__page_description {
          value
        }
        general_page_content__title {
          value
        }
        general_page_content__header_image {
          value {
            url
            description
          }
        }
        general_page_content__responsive_header_image {
          linked_items {
            ... on KontentItemBannerImage {
              id
              elements {
                desktop {
                  value {
                    url
                    description
                  }
                }
                mobile {
                  value {
                    description
                    url
                  }
                }
                tablet {
                  value {
                    description
                    url
                  }
                }
              }
            }
          }
        }
        general_page_content__kiwivip_signup {
          value {
            name
            codename
          }
        }
        general_page_content__kiwivip_signup_version {
          value {
            name
            codename
          }
        }
        general_page_content__title_position{
          value{
            codename
          }
        }
        url {
          value
        }
      }
  }
}
`

export default CompetitionLandingPage
